document.addEventListener('turbolinks:load', function (event) {
    $(window).scroll(function () {
        let threshold = 50;
        if ($('#contact').length > 0) {
            threshold = 0;
        }

        if ($(this).scrollTop() > threshold) {
            $('nav').addClass("navbar-scrolled");
        }
        else {
            $('nav').removeClass("navbar-scrolled");
        }
    });

    $('.navbar-toggler').click(function() {
       $('nav.navbar').toggleClass('expanded');
    });

    $('.carousel').carousel({
        interval: 5000,
        pause: false
    });
});